$brand_bg_yellow: #FDC648;
$brand_bg_secondary: #EBEBEB;
$bg_danger: #B23316;
$bg_success: #258E41;
$brand_black: rgba(14, 14, 14, 1);


$pure_white: #ffffff;
$pure_black: #000000;
$p_black:#797979;
$p1_black: #282938;
$p2_black:#424141;

$p_brown: #B77848;

$white_opacity7: rgba(255, 255, 255, 0.7);
$white_opacity5: rgba(255, 255, 255, 0.5);
$white_opacity05: rgba(255, 255, 255, 0.05);
$white_opacity3: rgba(255, 255, 255, 0.3);
$white_opacity1: rgba(255, 255, 255, 0.1);


$bdr_rds5: 5px;
$bdr_rds8: 8px;
$bdr_rds10: 10px;
$bdr_rds14: 14px;

$p10: 10px;
$p15: 15px;
$p20: 20px;


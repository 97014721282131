
/* ================================================= */
/* ================================================= */
@import './Variables';



.fs-8,
.fs_8{
  font-size: 8px !important; 
}

.fs-10,
.fs_10{
  font-size: 10px !important; 
}
.fs-12,
.fs_12{
  font-size: 12px !important; 
}
.fs-14,
.fs_14{
  font-size: 14px !important; 
}
.fs-15,
.fs_15{
  font-size: 15px !important; 
}
.fs-16,
.fs_16{
  font-size: 16px !important; 
}
.fs-18,
.fs_18{
  font-size: 18px !important; 
}
.fs-20,
.fs_20{
  font-size: 20px !important; 
}
.fs-22,
.fs_22{
  font-size: 22px !important; 
}
.fs-24,
.fs_24{
  font-size: 24px !important; 
}
.fs-25,
.fs_25{
  font-size: 25px !important; 
}
.fs-28,
.fs_28{
  font-size: 28px !important; 
}
.fs-30,
.fs_30{
  font-size: 30px !important; 
}
.fs-35,
.fs_35{
  font-size: 35px !important; 
}
.fs-40,
.fs_40{
  font-size: 40px !important; 
}
.fs-45,
.fs_45{
  font-size: 45px !important; 
}
.fs-50,
.fs_50{
  font-size: 50px !important; 
}
.fw-300,
.fw_300{
  font-weight: 300 !important; 
}
.fw-400,
.fw_400{
  font-weight: 400 !important; 
}
.fw-500,
.fw_500{
  font-weight: 500 !important; 
}
.fw-600,
.fw_600{
  font-weight: 600 !important; 
}
.fw-700,
.fw_700{
  font-weight: 700 !important; 
}
.fw-800,
.fw_800{
  font-weight: 800 !important; 
}

.text_brand_yellow{
  color: $brand_bg_yellow !important;
}
.text_white{
  color: $pure_white !important;
}

.text_white_opacity7{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text_white_color_half{
  color: rgba(255, 255, 255, 0.5) !important;
}
.text_white_opacity05{
  color: rgba(255, 255, 255, 0.05) !important;
}
.text_white_opacity3{
  color: rgba(255, 255, 255, 0.3) !important;
}
.text_white_opacity1{
  color: rgba(255, 255, 255, 0.1) !important;
}
.text_light{
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-justify {
  text-align: justify !important;
}
